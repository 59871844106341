import React from "react";
import { useState } from "react";
import contact from '../assets/images/contact.svg'
import emailjs from 'emailjs-com';
export default function GetInTouch() {
    const [successMessage, setSuccessMessage] = useState('');
    function sendEmail(e) {
    e.preventDefault();    

        emailjs.sendForm('service_q6jgdyp', 'template_y09ipln', e.target, 'qwVkpc5lwz-h82LKJ')
            .then((result) => {
                setSuccessMessage('Your message has been sent successfully.');
                //window.location.reload() 
                setTimeout(() => {
                    setSuccessMessage('');
                }, 5000); // Clear the success message after 5 seconds
                e.target.reset(); // Reset the form fields
            }, (error) => {
                console.log(error.text);
      });
    }

    // first open on  https://stackoverflow.com/questions/55795125/how-to-send-email-from-my-react-web-application

    // function sendEmail(e) {
    //     e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    
    //     emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
    //       .then((result) => {
    //           window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //   }
    return(
        <section className="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800" id="contact">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="font-semibold text-2xl leading-normal mb-4">Get in touch </h3>

               
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={contact}  alt=""/>
                    </div>

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <form onSubmit={sendEmail}>
                                    <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div className="lg:col-span-6">
                                            <label htmlFor="from_name" className="font-semibold">Your Name:</label>
                                            <input name="from_name" id="name" type="text" className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Name"/>
                                        </div>
        
                                        <div className="lg:col-span-6">
                                            <label htmlFor="from_email" className="font-semibold">Your Email:</label>
                                            <input name="from_email" id="email" type="email" className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Email"/>
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                            <input name="subject" id="subject" className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Subject"/>
                                        </div>
    
                                        <div className="lg:col-span-12">
                                            <label htmlFor="html_messages" className="font-semibold">Your Comment:</label>
                                            <textarea name="html_messages" id="comments" className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <input type="submit"  id="submit" name="send" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-blue-400 text-white mt-2" value="Send Message"/>
                                </form>
                                {successMessage && <div className="success-message">{successMessage}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}